import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import questions from "../data/questions";
import "../styles/Quiz.css";
import female from "./female.jpeg";
import male from "./male.png";
import axios from "axios";

function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleAnswer = async (answer) => {
    setAnswers({ ...answers, [currentQuestion]: answer });

    // If the current question is the gender selection question
    if (currentQuestion === 0) {
      try {
        setIsLoading(true);
        // Send the gender selection to the backend
        await axios.post(
          "https://surveyapi-lab0.onrender.com/api/increment-" +
            (answer === "Male" ? "male" : "female")
        );
      } catch (error) {
        console.error("There was an error updating the gender count!", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      navigate("/result", { state: { answers } });
    }
  };

  const question = questions[currentQuestion];

  const minFill = 3;
  const progressPercentage = Math.max(
    minFill,
    ((currentQuestion + 1) / questions.length) * 80
  );

  return (
    <div className="quiz-container">
      {/* Logo Image */}
      <div className="logo-container">
        <img
          style={{
            width: "35%",
            height: "auto",
            objectFit: "contain",
            maxWidth: "250px",
            margin: "0 auto",
            marginLeft: "10px",
            marginBottom: "5px",
          }}
          src="/logoo.png"
          alt="Logo"
          className="logo"
        />
      </div>

      {currentQuestion === 0 ? (
        <>
          <h1
            style={{
              color: "#2980b9",
            }}
          >
            Cinsiyetini Seç
          </h1>{" "}
          <br />
          <br />
          <br />
          <div className="gender-selection">
            <div className="gender-option" onClick={() => handleAnswer("female")}>
              <div className="image-container">
                <img src={female} alt="Male" />
              </div>
              <button
                style={{
                  marginTop: "-2px",
                }}
              >
                KADIN
              </button>{" "}
            </div>
            <div
              className="gender-option"
              onClick={() => handleAnswer("Male")}
            >
              <div className="image-container">
                <img src={male} alt="Male" />
              </div>
              <button>ERKEK</button>
            </div>
          </div>
          {/* Legal Disclaimer */}
          <p
            className="legal-disclaimer"
            style={{
              fontSize: "12px",
              color: "#666",
              textAlign: "center",
              marginTop: "20px",
              padding: "0 20px",
            }}
          >
            Cinsiyet seçtiğinizde
            <Link to="/legal/kvkk" style={{ color: "#2980b9" }}>
              {" "}
              KVKK Aydınlatma Metni
            </Link>{" "}
            ve
            <Link to="/legal/consent" style={{ color: "#2980b9" }}>
              {" "}
              Açık Rıza Metni
            </Link>
            'ni kabul etmiş olursunuz.
          </p>
          <p
            style={{
              fontSize: "10px",
              color: "#999",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <br />
            2024 © ALOHA DİJİTAL AKADEMİ
          </p>
        </>
      ) : (
        <>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
          <h2>{question.text}</h2>
          {question.options.map((option, index) => (
            <button key={index} onClick={() => handleAnswer(option)}>
              {option}
            </button>
          ))}
        </>
      )}
    </div>
  );
}

export default Quiz;
