// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Quiz from "./compponents/Quiz";
import Result from "./compponents/Result";
import LegalPage from "./compponents/LegalPage";
import "./styles/App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Quiz />} />
          <Route path="/result" element={<Result />} />
          <Route path="/legal/:type" element={<LegalPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;