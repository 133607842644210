import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/Result.css";
import axios from "axios";
import Confetti from "react-confetti";

function Result() {
  const location = useLocation();
  const { answers, userInfo } = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({ name: "", phone: "" });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dynamicScore, setDynamicScore] = useState(90);
  const [timeRemaining, setTimeRemaining] = useState(300); // 5 minutes in seconds
  const [showModal, setShowModal] = useState(false);
  const [confetti, setConfetti] = useState(false);

  const WhatsAppIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white"
      style={{ marginRight: "8px", verticalAlign: "middle" }}
    >
      <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
    </svg>
  );

  // Adjusted button style for consistent width and height
  const formElementStyle = {
    width: "90%",
    padding: "10px 15px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginBottom: "10px",
    fontSize: "16px",
  };

  const buttonStyle = {
    ...formElementStyle,
    width: "90%",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setDynamicScore(generateRandomScore());
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (timeRemaining > 0 && !formSubmitted) {
      const countdownTimer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(countdownTimer);
    }
  }, [timeRemaining, formSubmitted]);

  const generateRandomScore = () => {
    const min = 90;
    const max = 95;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/908507579427", "_blank");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[+]*[0-9]{10,15}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(formData.phone)) {
      setErrorMessage("Geçerli bir telefon numarası giriniz.");
      return;
    }
    try {
      await axios.post(
        "https://surveyapi-lab0.onrender.com/api/contact",
        formData
      );
      setFormSubmitted(true);
      setShowModal(true);
      setConfetti(true);
      setTimeout(() => setConfetti(false), 15000); // Stop confetti after 15 seconds
    } catch (error) {
      console.error("Error submitting the form:", error);
      setErrorMessage("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <p className="loader-message">
          Geleceğin Yazılımcısı Olma Potansiyelin Hesaplanıyor...
        </p>
      </div>
    );
  }

  return (
    <div className="result-container">
      {confetti && <Confetti />}
      <h1>Sonuç </h1>
      <p style={{ fontSize: "16px" }}>
        Geleceğin Yazılımcısı Olmaya Hazırsın! <br />
        Potansiyelin Yüksek ve Yollar Sana Açık!
      </p>
      <div className="score-bar">
        <div className="score-fill" style={{ width: `${dynamicScore}%` }}>
          <span className="score-text">{dynamicScore}%</span>
        </div>
      </div>
      <br />
      <p className="result-message" style={{ color: "gray" }}>
        Aşağıdaki formu doldur ilk 100 kişiye özel indirim kodunu kazan!
      </p>

      {!formSubmitted ? (
        <>
          <p style={{ fontSize: "17px" }}>
            Formu doldurmak için{" "}
            <span
              style={{
                color: "red",
              }}
            >
              {formatTime(timeRemaining)}
            </span>{" "}
            dk kaldı.
          </p>
          <form onSubmit={handleSubmit} className="contact-form">
            <input
              type="text"
              name="name"
              placeholder="Ad Soyad"
              value={formData.name}
              onChange={handleChange}
              required
              style={formElementStyle}
            />
            <input
              type="tel"
              name="phone"
              placeholder="Telefon Numarası"
              value={formData.phone}
              onChange={handleChange}
              required
              style={formElementStyle}
            />
            <button
              type="submit"
              style={{
                ...buttonStyle,
                backgroundColor: "#007bff",
                color: "white",
              }}
            >
              Bilgi Almak İstiyorum
            </button>
            <button
              onClick={handleWhatsAppClick}
              className="whatsapp-button"
              style={{
                ...buttonStyle,
                backgroundColor: "#25D366",
                color: "white",
                // fontSize:"13px"
              }}
            >
              <WhatsAppIcon />
              WhatsApp ile İletişime Geç
            </button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </>
      ) : (
        <p
          style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
        >
          Teşekkürler, formun başarıyla gönderildi!
        </p>
      )}
    </div>
  );
}

export default Result;
