const questions = [
  {
    text: "👫 Cinsiyetin Nedir",
    options: ["Erkek", "Kadın"],
  },
  {
    text: "Yazılım dünyası ne zamandan beri ilgini çekiyor?",
    options: [
      "🧒 Çocukken bilgisayarla tanıştım ve bu dünyaya çekildim",
      "✨ Lisedeyken yazılımın büyüsüne kapıldım",
      "🎓 Üniversitede yazılımın gizemlerini keşfettim",
      "🚀 Son zamanlarda bu alanın ne kadar heyecan verici olduğunu fark ettim",
    ],
  },
  {
    text: "Bir eğitimden beklentilerin nelerdir?",
    options: [
      "Teknik eğitim - bunu heryerde buluyorum zaten 🧐",
      "Teknik Eğitim + Proje - işte bu güzel olur ⭐️",
      "Teknik eğitim + Proje + Staj - Bu fırsat bana tüm kapıları açar😍 ",
    ],
  },

  {
    text: " Ayda ne kadar kazanmak seni mutlu eder ve hayatını daha iyi hale getirir?",
    options: [
      "💵 30.000 - 40.000 TL",
      "💸 40.000 - 50.000 TL",
      "💰 50.000 - 60.000 TL",
      "🏆 60.000 TL ve üzeri",
    ],
  },
  {
    text: "Eğitim aldığın şirketin hangi sektörlerde aktif olmasını istersin?",
    options: [
      "💻 Teknoloji",
      "📚 Yazılım Akademisi",
      "🤖 Yapay Zeka",
      "📱 Mobil Uygulama",
      "🌟 Hepsi",
    ],
  },
  {
    text: " Yazılım eğitimi sürecinde en çok hangi konularda kendini geliştirmek istersin?",
    options: [
      "🎨 Front-End ile Harika Tasarımlar",
      "💻 Back-End ile Güçlü Sistemler",
      "📱 Mobil Uygulama ile Etkileyici Deneyimler",
      "😎 Full-Stack ile Uçtanuca Çözümler",
    ],
  },
  {
    text: " Hayalindeki çalışma ortamını hangisi tanımlar?",
    options: [
      "☕️ Masa Başı & Kahvem",
      "🏠 Evde Pijamalarımla",
      "✈️ Dünyanın Herhangi Bir Yerinde",
      "🏖️ Deniz, Kum ve Laptop",
    ],
  },
];

export default questions;
