// src/components/LegalTexts.jsx
import React, { useEffect, useRef, useState } from "react";

const LegalTexts = ({ type }) => {
  const [contentHeight, setContentHeight] = useState("auto");
  const contentRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setContentHeight(`${contentRef.current.scrollHeight}px`);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, [type]);

  const KVKKText = `ALOHA DİJİTAL BİLİŞİM EĞİTİM VE DANIŞMANLIK A.Ş. KİŞİSEL VERİLERİN KORUNMASI KANUNU AYDINLATMA METNİ

  Veri Sorumlusunun Kimliği:

Veri sorumlusu sıfatıyla, Aloha Dijital Bilişim Eğitim ve Danışmanlık A.Ş. ("Şirket"), 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") uyarınca, kişisel verilerinizi aşağıda açıklanan çerçevede işlemektedir.

  İşlenen Kişisel Veriler ve İşleme Amaçları:

Şirketimiz, quiz katılımcılarından elde edilen kişisel verileri (ad, soyad, yaş, cinsiyet, iletişim bilgileri ve diğer demografik veriler) aşağıdaki amaçlarla işlemektedir:

  Hizmetlerin Sunulması ve Operasyonel Süreçler: Kullanıcı deneyiminin iyileştirilmesi, hizmet kalitesinin artırılması, müşteri destek hizmetlerinin sunulması, operasyonel süreçlerin yönetimi ve yürütülmesi.
  Pazarlama Faaliyetleri: Pazarlama analizleri yapmak, müşteri davranışlarını anlamak ve kişiselleştirilmiş içerik ve reklam sunmak.
  Yasal Yükümlülüklerin Yerine Getirilmesi: Mevzuat gereği zorunlu olan yükümlülüklerin yerine getirilmesi ve denetim süreçlerinin yürütülmesi.
  Güvenlik ve Denetim: Bilgi güvenliği süreçlerinin yürütülmesi, hukuki ve ticari risklerin yönetimi.

  Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi:

Kişisel verileriniz, elektronik ortamda doldurulan quiz formları, dijital platformlar ve diğer elektronik iletişim araçları üzerinden, otomatik yollarla toplanmaktadır. Verileriniz, KVKK'nın 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartlarına dayanılarak işlenmektedir.

  Kişisel Verilerin Aktarılabileceği Taraflar ve Aktarım Amaçları:

Toplanan kişisel verileriniz, yalnızca işbu aydınlatma metninde belirtilen amaçlarla sınırlı olmak kaydıyla; hizmet sağlayıcılar, iş ortaklarımız ve kanunen yetkili kamu kurumları ile paylaşılabilir.

  Kişisel Verilerin Saklanma Süresi:

Kişisel verileriniz, işbu metinde belirtilen işleme amaçlarının gerektirdiği süre boyunca saklanacak ve ilgili mevzuatta belirtilen zamanaşımı süreleri saklı kalmak kaydıyla silinecek, yok edilecek veya anonim hale getirilecektir.

  KVKK Kapsamındaki Haklarınız:

Kişisel verilerinize ilişkin olarak KVKK'nın 11. maddesi uyarınca sahip olduğunuz haklar şunlardır:

  Kişisel verilerinizin işlenip işlenmediğini öğrenme,
  Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
  Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
  Kişisel verilerinizin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme,
  Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,
  KVKK'nın 7. maddesi uyarınca kişisel verilerinizin silinmesini veya yok edilmesini isteme,
  Kişisel verilerinizin düzeltilmesi veya silinmesi ile ilgili yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
  İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
  Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.

KVKK kapsamında sahip olduğunuz haklarınızı kullanmak için bize kvkk@alohadijital.com adresinden ulaşabilirsiniz.
`;

  const ConsentText = `Açık Rıza Metni

  ALOHA DİJİTAL BİLİŞİM EĞİTİM VE DANIŞMANLIK A.Ş. AÇIK RIZA METNİ

  Quiz'e katılarak, aşağıda detaylı olarak açıklanan hususlarda kişisel verilerinizin işlenmesine ve kullanılmasına ilişkin açık rızanızı talep ediyoruz.

  İşlenecek Kişisel Veriler ve İşleme Amaçları:

      Kişisel Bilgiler: Ad, soyad, yaş, cinsiyet, iletişim bilgileri ve diğer demografik veriler.
      İşleme Amaçları:
          Kişiselleştirilmiş hizmet sunumu,
          Pazarlama ve reklam faaliyetlerinin yürütülmesi,
          Kullanıcı deneyiminin iyileştirilmesi,
          Yasal düzenlemelerden kaynaklanan yükümlülüklerin yerine getirilmesi.

  Veri Aktarımı:

  Kişisel verileriniz, hizmet sağlayıcılar, iş ortakları ve yasal gereklilikler çerçevesinde kanunen yetkili kamu kurumlarına aktarılabilecektir.

  Veri Saklama Süresi:

  Verileriniz, işbu açık rıza metninde belirtilen amaçların gerektirdiği süre boyunca veya yasal saklama süresi boyunca muhafaza edilecektir.

  Haklarınız:

  KVKK kapsamında, verilerinizin işlenmesine dair onayınızı dilediğiniz zaman geri çekme hakkınız bulunmaktadır. Rızanızı geri çekmek için kvkk@alohadijital.com adresine e-posta gönderebilirsiniz.

  Onay:

  İşbu açık rıza metnini okudum, anladım ve kişisel verilerimin belirtilen amaçlar doğrultusunda işlenmesine ve kullanılmasına açık rıza veriyorum.`;

  return (
    <div
      className="relative min-h-screen bg-white bg-contain bg-center bg-no-repeat"
      style={{
        // backgroundImage: "url('/logoo.png')",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        opacity: 0.9,
      }}
    >
      <div ref={contentRef} className="relative z-10 p-4">
        {type === "kvkk" && (
          <>
            <h2 className="text-2xl font-bold mb-4">KVKK Aydınlatma Metni</h2><br />
            <p className="whitespace-pre-wrap">{KVKKText}</p>
          </>
        )}
        {type === "consent" && (
          <>
            <h2 className="text-2xl font-bold mb-4">Açık Rıza Metni</h2><br />
            <p className="whitespace-pre-wrap">{ConsentText}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default LegalTexts;
