// src/components/LegalPage.jsx
import React from "react";
import { useParams, Link } from "react-router-dom";
import LegalTexts from "./LegalTexts";
import "./LegalPage.css";

const LegalPage = () => {
  const { type } = useParams();

  return (
    <div className="all">
      <div className="legal-page">
        {/* <h1>Yasal Metinler</h1> */}
        {/* <Link to="/">Geri Dön</Link> */}
        <br />
        <br />

        <LegalTexts type={type} />
        <br />
        <Link to="/">Geri Dön</Link>
        <br />
        <br />
      </div>
    </div>
  );
};

export default LegalPage;
